.Input {
    background: #FFFFFF;
    height: 32px;
    width: calc(260/1361*100%);
    border: 0.714286px solid #E0E0E0;
    border-radius: 7.14286px;
    box-sizing: border-box;
    margin-left: 2%;
}

.Submit {
    margin: 20px;
    width: 101px;
    height: 27px;
    background: #007AD4;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    border-color: #007AD4;
}

.container {
    background: #FFFFFF;
    /* box-shadow: 0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0;
    border-radius: 12px 12px 0px 0px; */
    height: 100%;
    margin-left: 2%;
    
    margin-right: 4%;
}

.tabdiv {
    margin-top: 0.5em;
    margin-left: 2%;
}

.tabcontainer {
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0;


}

.parent {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    height: 100%;
}

.child1 {
    /* align-items: center; */
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: fit-content;
    /* height: 100%; */
    height: 50%;
    width:98%;
    /* margin: auto; */
}

.child2 {
    flex: 4;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 1%;
}

.paperstyle {
 height: 80%;
}

.home-info-cards {
    margin: 10px 3.6em 0 3.6em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1%;   
}

.home-info-individual-card:hover {
    /* backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased; */

    transition: transform 0.5s;
    
    transform: scale(1.1);
}

.dinline{
    display: inline-block;
}
.padding-s{
    padding: 2px;
}
.padding-m{
    padding: 5px;
}.padding-l{
    padding: 8px;
}.padding-xl{
    padding: 12px;
}
.padding-side-s{
    padding: 0px 2px;
}
.padding-side-l{
    padding: 0px 8px;
    
}.padding-side-m{
    padding: 0px 5px;
    
}.padding-side-xl{
    padding: 0px 35px;
}.padding-sides{
    padding: 0px 12px;
}
.box-shadow{
    box-shadow: 0px 0px 2px .5px rgba(0, 0, 0, 0.281);
}
.critical{
    color: #cf0100 !important;
}
.high{
    color: #e6762c !important;

}
.priority-critical {
    color: #cf0100 !important;
  }
  .priority-high {
    color: #e6762c !important;

  }
  .active {
    background-color: #f1f5fe;
    font-weight: 500;
    box-shadow: 0px 5px 5px .1px rgba(0, 0, 0, 0.1);
  
  }
  .expand {
    display: flex !important;
    transition: all .2s ease-in-out;
    transform: scaleY(100%);
    
  }
  .expand-b {
    display: block !important;
    
  }
  .collapsed{
    transform: scaleY(0);
  }
  .text-light{
      color: rgba(128, 128, 128, 0.548);
      font-weight: 400;
  }
  .text_size-normal{
      font-size: 16px;
  }

.button_primary{
    background-color:#F1F5FE;
    color: #3026B9;
    border-radius: 5px;
    border: none;
    padding: 9px 10px;
    max-width: 200px;
    width: 80%;
    margin: 2px;
font-style: normal;
font-size: 12px;
line-height: 14px;
text-align: center;
font-weight: 600;
display: flex;
flex-direction: row;
justify-content: flex-start !important;
align-items: center;
}
.button_primary-s{
    width: 150px;
    height: max-content;
}
.stack{
    
}
.stack--item{
    display: flex;
    max-height: 60px;
    min-height: 40px;
    margin: 20px 0px;
    box-shadow: 0px 0px 3px .1px rgba(0, 0, 0, 0.15);
    flex-direction: row;
}
.small-icon-box {
    /* margin-right: 1.2em; */
    width: 29px;
    height: 29px;
    display: inline;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .home-info-icon {
} */

.home-info-separator {
    background: #AEAEAE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    height: 37px;
    width: 2px;
    margin-left: 1.2em;
}

/* .layout1 {
    display: none;
}

.layout2 {
    display: none;
}


@media (min-height: 874px)
{
    .layout1 {
        display: none;
    }
    .layout2 {
        display: block;
    }
} */
.wbHideScroll {
    overflow: hidden !important;
  }
  .wbScroll {
    overflow: overlay !important;
  }
  .wbScrollY {
    overflow-y: scroll;
  }
  .wbScrollX {
    overflow-x: scroll;
  }
  .wbScroll::-webkit-scrollbar {
    display: none;
    width: 0.25rem;
    height: 0.25rem;
  }
  .wbScroll:hover::-webkit-scrollbar {
    display: inline;
  }
  .wbScroll::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 1.5rem;
  }
  .wbScroll::-webkit-scrollbar:hover {
    width: 0.5rem;
    height: 0.5rem;
  }
  .wbScrollHover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
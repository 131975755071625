.maindiv{
background: #FFFFFF;
box-shadow: 0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0;
border-radius: 12px 12px 0px 0px;
height: 93%;
margin-right: 2%;
margin-left: 2%;
margin-top: 2%;
}

.table{
   height: 60vh;
   /* max-height: 93%;  */
   /* overflow: auto; */
}
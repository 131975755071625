
  
  .menu {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu li {
    position: absolute;
    list-style: none;
    top: 175px;
    left: 95px;
    transform-origin: 250px;
    transition: 0.5s;
    transition-delay: calc(0.1s * var(--i));
    transform: rotate(0deg) translateX(80px);
    border-radius: 50%;
  }
  
  .menu li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    background: #fff;
    border-radius: 50%;
  }
  
  .menu.active li {
    left: 0;
    transform: rotate(calc(360deg / 8 * var(--i)));
  }
  
  .toggle {
    position: absolute;
    color: #fff;
    width: 150px;
    height: 150px;
    background: #3e7ad4;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    font-size: 2em;
    transition: transform 1.25s;
  }
  
  .toggle:hover {
    background-color: #fff;
    border: 3px solid #3e7ad4;
    color: #3e7ad4;
  }

  .menu.active{
    background-color: #fff;
    box-shadow: none;
  }
  
  .menu.active .toggle {
    transform: rotate(360deg);
  }
  
  .icon {
    height: 100px;
    width: 100px;
    transform: rotate(calc(360deg / -8 * var(--i)));
  }
  
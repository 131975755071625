
.body-content {
    background-color: rgb(255, 255, 255);
    height: calc(100vh - 52px);
    width: 100%;
    overflow-y: auto;
}

.wbFloatLeft {
    height: 100%;
}


.wbMainPageHeight {
    height: calc(100vh - 52px);
    display: flex;
}


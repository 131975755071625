body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-header-row {
  background: #f1f5fe;
}

.ag-body-vertical-scroll {
  
  background: #f1f5fe;
}


/* .ag-root {
  overflow: overlay !important;
}
.ag-rootY {
  overflow-y: scroll;
}
.ag-rootX {
  overflow-x: scroll;
}
.ag-root::-webkit-scrollbar {
  display: none;
  width: 0.25rem;
  height: 0.25rem;
}
.ag-root:hover::-webkit-scrollbar {
  display: inline;
}
.ag-root::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 1.5rem;
}
.ag-root::-webkit-scrollbar:hover {
  width: 0.5rem;
  height: 0.5rem;
} */

/* table_box */
/* font color rgb(74, 71, 71) */
/* fontsize = 15px
    boxshadow = 0px 0px 20px .1px rgba(0, 0, 0, 0.557)
    padding = 10px
    margin = 10px

*/
/* .collapse_body--item 
    fontsize = 12px
    color: rgb(74, 71, 71);


*/

*{
  font-family: roboto;
}
html{
  box-sizing: border-box;
  
}
/* html::-webkit-scrollbar{
  width: 1px;
}
.table::-webkit-scrollbar{
  width: 1px;
} */

.ag-body-vertical-scroll {
  background: #f1f5fe;
}

.wbHideScroll {
  overflow: hidden !important;
}
.wbScroll, .MuiDataGrid-virtualScroller {
  overflow: overlay !important;
}
.wbScrollY {
  overflow-y: scroll;
}
.wbScrollX {
  overflow-x: scroll;
}
.wbScroll::-webkit-scrollbar, .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
  width: 0.25rem;
  height: 0.25rem;
}
.wbScroll:hover::-webkit-scrollbar, .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar {
  display: inline;
}
.wbScroll::-webkit-scrollbar-thumb, .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 1.5rem;
}
.wbScroll::-webkit-scrollbar:hover, .MuiDataGrid-virtualScroller::-webkit-scrollbar:hover {
  width: 0.5rem;
  height: 0.5rem;
}
.wbScrollHover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table_box {
  max-width: 80%;
  margin: 0 auto;
  font-size: 15px;
  box-shadow: 0px 0px 2px .5px rgba(0, 0, 0, 0.281);
  padding: 20px;
  height: max-content;
  margin: 100px auto;
  border-radius: 4px;

}
.table_mainbox{
  margin-right: 1%;
}

.table_header {
  background-color: #f1f5fe !important;
}
.table_card{
 min-height: 100px;
}

.table_card--collapsable {
  min-height: 200px;
  height: max-content;
  width: 100%;
  display: none;
  position: relative;
  flex-direction: column;
  padding: 10px 0px;

}
.table_card--input:checked {
  background-color: black;
}



.table_card,
.table_header {
  width: 100%;
  max-height: 50px;
  height: 50px;
  padding: 2px;
  background-color: rgb(255, 255, 255);
  transition: all .3s ease-in-out;

}
.table_card--items {
  width: calc(100% / 10);
  align-self: center; 
  cursor: pointer;
  text-align: center;
  align-items: center;
  font-size: 14px;
  max-height: 50px;
    /* color: rgb(87, 85, 85); */
}


.collapse_body--item {
  max-width: 200px;
  padding: 5px 10px;
  margin: 0px 30px;
}
.collapse_body--databox {
  width: 100%;
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: auto auto auto auto ;
  row-gap: -5px;
  grid-template-rows: 100px 100px;
  grid-auto-flow: row;
 /* justify-content: space-between; */
  padding: 5px;
}
.collapse_body--box{
    display: flex;
}
.collapse_body--item:last-child {
  /* max-width: 50px; */
  /* margin-right: auto; */
}
.collapse_body--buttonsbox{
    width: 30%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    height: max-content;
}

.collapse_body--item p {
  color: rgb(87, 75, 75);
  font-weight: 600;
  margin: 4px 0;
  text-transform: uppercase;
}
.collapse_body--item h5 {
  color: rgb(74, 71, 71);
  margin: 4px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
